<!-- Últimas remesas -->

<template>
  <div class="cobros_pagos_ultimasRemesas"> 
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">  
        <v-btn 
          v-on='on'
          v-on:click="ini_component"
          v-bind="$cfg.btra_cfg.standard"
          title="Lista Últimas Remesas">
            <v-icon>{{'mdi-format-list-bulleted'}}</v-icon>
        </v-btn>       
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Contenido -->
          <div class="contenedor conflex" style="width:610px"> 
            <div class="columna">
            
              <!-- Grid -->
              <base_Fgrid
                v-model="selected"
                :padre="stName" 
                :Entorno="Entorno"  
                height="300px"        
                :items-per-page=-1 
                show-select 
                single-select
                hide-default-footer 
                fixed-header     
                dense>

                <!-- Slot botonera Top -->
                <template v-slot:top_btns="{ }">         
                  <div style="padding:10px 0 0 10px;">
                    <div style="display:flex;justify-content:space-between;width:280px;padding-bottom:15px">
                      <v-btn
                        v-bind="$cfg.btra_cfg.standard"            
                        title="Ver Remesa"
                        @click="ver_remesa">
                          <v-icon dark>{{ "mdi-check" }}</v-icon>
                      </v-btn>  

                      <v-text-field
                        style="flex: 1 1 40%;padding-left:10px"
                        v-bind="$input"
                        v-model="schema.ctrls.txt.value"
                        :label="schema.ctrls.txt.label"
                        append-icon="mdi-magnify"
                        @keypress.13="filterRemesas"
                        @click:append="filterRemesas">
                      </v-text-field>

                    </div>           
                  </div>
                </template>             
              </base_Fgrid>
            </div>
          </div>
        </v-sheet> 
      </div>
    </v-dialog>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
      
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid },
    props: {
      padre: { type:String, default: '' }, 
      componenteTipo: { type:String, default:'F' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            txt: { f:'txt', label:'Búsqueda (Remesa)', value:""}
          }
        },
        api:'',
        stName:'stFcobros_pagos_ultimasRemesas',
        headers: {},
        selected:[],
        btra_permisos:{ extra:{}},

        dialog:false
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Últimas Remesas Pagos"; 
        this.Entorno.grid.autoload= false;

        this.headers = {
          header: [
            { text: "Remesa", value: "rem", width: "15%" },
            { text: "Fecha", value: "fh", width: "40%" , filtro:'fecha'},
            { text: "Cuentas", value: "num", width: "5%" },
            { text: "Fras", value: "fras", width: "5%" },
            { text: "Total", value: "total", width: "30%", filtro:'num' },
            { text: "Asiento", value: "asiento", width: "15%" }
         ],

        };

        this.Entorno.grid.headers = this.set_headers();
       
      },


      // obtengo los registros a mostrar
      async ini_component() {

        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'remesas',
          fnt:'lista',
          tip:1
        }};
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });       
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r });

         this.schema.ctrls.txt.value="";
      },

      //
      ver_remesa() {
        if (!this.selected.length) return;
      
        this.dialog= false;
        this.$emit('onEvent', { rem:this.selected[0].rem });
      },

      filterRemesas() {      
        let txt= this.schema.ctrls.txt.value;
              
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
        
          if (txt!= '') {          
            return (
              elem.rem.toLowerCase().includes(txt.toLowerCase()) ||
              elem.fh.includes(this.visual2sql({format: 'fh'}, txt))
            )
          }

          return elem;
        })})
        
        // limpio selección
        this.selected= [];      
      },

    }
  };
</script>
